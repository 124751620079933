 
import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// CRM app  React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";

// CRM app  React routes
import routes from "routes";
import HelpCenter from "pages/Support/HelpCenter"; 
import footerRoutes from "footer.routes";

// CRM app  React components
import MKBox from "components/MKBox"; 
import DefaultNavbar from "layouts/Navbars/DefaultNavbar";
import DefaultFooter from "layouts/Footers/DefaultFooter";
import customRoutes from "customRoutes";
// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";
import { MetaTags } from "react-meta-tags";
import ServiceRequest from "pages/Support/ServiceRequest";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

  apiKey: "AIzaSyD-Wtx3zgdKFZBSo5beIqIK6d4xcoNZ1Uw",

  authDomain: "crm-test-60f21.firebaseapp.com",

  projectId: "crm-test-60f21",

  storageBucket: "crm-test-60f21.appspot.com",

  messagingSenderId: "967317546338",

  appId: "1:967317546338:web:205ef4b9668c2de732f83b",

  measurementId: "G-R52V2CLBQK"

};


// Initialize Firebase

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (<>
  
  <MetaTags>
       <title>Customer Portal</title>
  </MetaTags>

    <ThemeProvider theme={theme}>
      <CssBaseline />
      
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "buy now",
          color: "info",
        }}
        sticky//transparent
        // light
      />
      <Routes>
        {getRoutes(routes)}
        {getRoutes(customRoutes)}
        <Route path="/presentation" element={<Presentation />} />
        <Route path="/home" element={<HelpCenter />} />
        <Route path="/" element={<ServiceRequest />} />
        <Route path="*" element={<Navigate to="/" />} /> 
      </Routes>
      
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </ThemeProvider>
  </>);
}
