 
import axios from "axios"; 
import { DataManagementService } from "./dataManagementService";
export class HttpService {
  static getHeaderAndURL = () => {
    // let baseURL = "https://aks-cluster-dev.ethiopianairlines.com/"; //Development URL
    // let baseURL = "https://api.bfamilyfilmschool.com"; // Production URL
    // let baseURL=""; // Production URL
    // let baseURL="https://elbh-test.fa.em2.oraclecloud.com/crmRestApi/resources/latest"; // Production URL
    let baseURL={}; // Production URL
    //https://elbh-test.fa.em2.oraclecloud.com/km/api/latest/content
    // let baseURL="https://elbh-test.fa.em2.oraclecloud.com/km/api/v1"; // Production URL
    // https://elbh.fa.em2.oraclecloud.com/
    // let baseURL="https://api.dillaketemaastedader.bfamily.et"; // Production URL
    // if(window.location.hostname.includes("localhost") ){
    //     baseURL = "http://localhost:3000"; //Development URL
    // }
// let oracleBaseURL="https://elbh-test.fa.em2.oraclecloud.com"//test
let oracleBaseURL="https://elbh.fa.em2.oraclecloud.com"//prod"/crmRestApi/resources/latest",//"/contacts/"+passengerContactData.PartyNumber
baseURL={
  defualt:oracleBaseURL+"/km/api/latest",
  api1:oracleBaseURL+"/km/api/latest",
  api2:oracleBaseURL+"/km/api/v1",
  api3:oracleBaseURL+"/crmRestApi/resources/latest", 
  api4:oracleBaseURL+"/srt/api/v1",
  api5:oracleBaseURL+"/rest/ofscCore/v1",
}
    let token = "";
    // let token = localStorage.getItem("holidaysToken");

    let headers = {
      // Authorization: "Bearer " + token, //the token is a variable which holds the token
      // IsDevelopment: true,
      // IsDevelopment: false,
      // ContentType: "application/json",
      // Accept: "application/json",
    };

    return {
      baseUrl: baseURL,
      token: token,
      headers: headers,
      auth: {
        // username: loginCredentials.username,//'Integration.User',
        // password:loginCredentials.password// 'Crm@1234'
        // username:  'Integration.User',//test
        // password:  'Crm@1234'//test
        username: "Supper.User",///prod
        password: "CrmUser@1234"////prod

      },
      withCredentials:{withCredentials: true, credentials: 'include'}
    };
  };


  static getService = async (relativePath, AdditionalData, queryString = "",apiType) => {
    let response={success:false,data:null,message:""}
    let headerAndURL = this.getHeaderAndURL();
    let baseUrl = headerAndURL.baseUrl.defualt//"https://api.bfamilyfilmschool.com/";
  
    if(apiType){
       baseUrl = headerAndURL.baseUrl[apiType]
        if(apiType=="api1"){
          baseUrl = headerAndURL.baseUrl.api1//"https://api.bfamilyfilmschool.com/";
          } 
        if(apiType=="api2"){
            baseUrl = headerAndURL.baseUrl.api2//"https://api.bfamilyfilmschool.com/";
          }
    }

    let allAdditonalData={auth:headerAndURL.auth} 
    // allAdditonalData=AdditionalData;
    // allAdditonalData.headers=headerAndURL.headers;
    // allAdditonalData.withCredentials=true,
    // allAdditonalData.credentials='include'
     

     await axios.get(baseUrl + relativePath, allAdditonalData) 
     .then(function (res) { 
      // response= res
      // console.log(res)
      if(res.status== 200)  {
      response.success=true
      response.message=res.statusText
      response.data=res.data
      }
     else if (res.status== 400)  {
        //please login first///
        // response.success=res.data.success
        response.message=res.data
        // response.data=res.data
        }
      else{
        response.success=false
        response.message=res.data||"Problem occured during fetch data"   
      }
    })
    .catch(function (error) {  
      response.success=false
      response.error=error
      response.message="Problem occured during fetch dataaaa" 
    });
    
    return response;   


  };

  static postService = async  ( relativePath, requestData , AdditionalData, queryString = "",apiType) => {
    let response={success:false,data:null,message:""}
    let headerAndURL = this.getHeaderAndURL();
    let baseUrl = headerAndURL.baseUrl.defualt//"https://api.bfamilyfilmschool.com/";
    if(apiType){ 
       baseUrl = headerAndURL.baseUrl[apiType]
    if(apiType=="api1"){
       baseUrl = headerAndURL.baseUrl.api1//"https://api.bfamilyfilmschool.com/";
      } 
    if(apiType=="api2"){
        baseUrl = headerAndURL.baseUrl.api2//"https://api.bfamilyfilmschool.com/";
      }
    if(apiType=="api4"){
        baseUrl = headerAndURL.baseUrl.api4//"https://api.bfamilyfilmschool.com/";
      }
    }
    let allAdditonalData={auth:headerAndURL.auth} 
    //  }
    // allAdditonalData=AdditionalData;
    // allAdditonalData.headers=headerAndURL.headers;
    
    // allAdditonalData.withCredentials=true,
    // allAdditonalData.credentials='include'
    
    // let baseUrl = headerAndURL.baseUrl + "flightapi-etholidays/V1.0/api/";
    // axios.post(baseUrl+"api/photos", formData,additionalData)
    await axios.post(baseUrl + relativePath + queryString, requestData, allAdditonalData 
    ) 
    .then(function (res) { 
      // response= res
      // console.log(res)
      if((res.status> 199)||res.status<300)  {
      response.success=true
      response.message=res.statusText
      response.data=res.data
      }
     else if (res.status== 400)  {
        //please login first///
        // response.success=res.data.success
        response.message=res.data
        // response.data=res.data
        }
      else{
        response.success=false
        response.message=res.data||"Problem occured during fetch data"  
      }
    })
    .catch(function (error) {  
      // console.log(error)
      response.success=false
      response.error=error
      response.message=error.response.data||"Problem occured during posting data" 
    });

return response;

  };
 
  
  static patchService = async  ( relativePath, requestData , AdditionalData, queryString = "",apiType) => {
    let response={success:false,data:null,message:""}
    let headerAndURL = this.getHeaderAndURL();
    let baseUrl = headerAndURL.baseUrl.defualt//"https://api.bfamilyfilmschool.com/";
    if(apiType){ 
       baseUrl = headerAndURL.baseUrl[apiType]
    if(apiType=="api1"){
       baseUrl = headerAndURL.baseUrl.api1//"https://api.bfamilyfilmschool.com/";
      } 
    if(apiType=="api2"){
        baseUrl = headerAndURL.baseUrl.api2//"https://api.bfamilyfilmschool.com/";
      }
    if(apiType=="api4"){
        baseUrl = headerAndURL.baseUrl.api4//"https://api.bfamilyfilmschool.com/";
      }
    }  
    let allAdditonalData={auth:headerAndURL.auth} 
    //  }
    // allAdditonalData=AdditionalData;
    // allAdditonalData.headers=headerAndURL.headers;
    
    // allAdditonalData.withCredentials=true,
    // allAdditonalData.credentials='include'
    
    // let baseUrl = headerAndURL.baseUrl + "flightapi-etholidays/V1.0/api/";
    // axios.post(baseUrl+"api/photos", formData,additionalData)
    await axios.patch(baseUrl + relativePath + queryString, requestData, allAdditonalData 
    ) 
    .then(function (res) { 
      // response= res
      // console.log(res)
      if((res.status> 199)||res.status<300)  {
      response.success=true
      response.message=res.statusText
      response.data=res.data
      }
     else if (res.status== 400)  {
        //please login first///
        // response.success=res.data.success
        response.message=res.data
        // response.data=res.data
        }
      else{
        response.success=false
        response.message=res.data||"Problem occured during fetch data"  
      }
    })
    .catch(function (error) {  
      // console.log(error)
      response.success=false
      response.error=error
      response.message="Problem occured during fetch dataaaa" 
    });

return response;

  };
 

  static postServiceDefualt = (requestData, relativePath, queryString = "") => {
    let headerAndURL = this.getHeaderAndURL();
    let baseUrl = headerAndURL.baseUrl + "paymentapi-etholidays/V1.0";
    return axios.post(baseUrl + relativePath + queryString, requestData, {
      headers: headerAndURL.headers,
    });
  };
 
//   static getIPService = (ipData) => {
//     const publicIp = require("public-ip");
//     let headerAndURL = this.getHeaderAndURL();
//     let baseUrl = headerAndURL.baseUrl + "masterdataapi-etholidays/api/";

//     var relativePath2 = "IpDetail/Get?IpAddress=" + ipData;

//     return axios.get(baseUrl + relativePath2, {
//       headers: headerAndURL.headers,
//     });
//   };

  static getToken = () => {
    let baseUrl = this.getHeaderAndURL().baseUrl;
    let tokenApiUrl = "authorizationapi-marketplace/api/OAuth/Generate";
    return axios.post(
      baseUrl + tokenApiUrl,
      { Username: "me@gmail.com", Password: "123456" },
      {
        headers: {
          isdevelopment: "true",
          // isdevelopment: "false",
          ContentType: "application/json",
          Accept: "application/json",
        },
      }
    );
  };

  

  static checkToken() {
    const token = localStorage.getItem("holidaysToken");

    if (token == null || token == "null") {
      return this.getToken().then((response) => {
        let newToken = response.data.token;
        // localStorage.setItem("holidaysToken", newToken);
      });
    } else {
      //  var decodedToken=jwt.decode(token, {complete: true});
      // var dateNow = new Date();
      // console.log(decodedToken);
      // console.log(decodedToken.payload.exp);
      // console.log(dateNow.getTime());
      //  if(decodedToken.payload.exp < dateNow.getUTCSeconds())
      //  {
      //   return this.getToken().then((response) => {
      //      let newToken =  response.data.token;
      //      localStorage.setItem("holidaysToken", newToken);
      //    })
      //  }
      return this.getToken().then((response) => {
        let newToken = response.data.token;
        // localStorage.setItem("holidaysToken", newToken);
      });
    }
  } 
 


}
