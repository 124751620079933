/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
var convert = require('xml-js');
import parse from 'html-react-parser';
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import queryString from "query-string"; 
// CRM app  React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// import bgImage from "assets/images/bg3.jpg";
import bgImage from "assets/images/ethiopian-collection/aircraft1.jpg";
// HelpCenter page components
import FaqCollapse from "pages/Support/HelpCenter/components/FaqCollapse";
import { HttpService } from "Api-Services/httpService";
import axios from "axios";
import { mostPopularAnswer } from "Api-master-datas/masterDatas";
import { mostPopularAnswer6 } from "Api-master-datas/masterDatas";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import { CircularProgress, Divider, IconButton, InputBase, Menu, Paper } from "@mui/material";
import { Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { MetaTags } from "react-meta-tags";
function AskQuestion() {
  const [collapse, setCollapse] = useState(false);
  const [forceRerender, setForceRerender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [relatedQuestions, setRelatedQuestions] = useState(null);
  const [searchQuestionText, setSearchQuestionText] = useState("");
  const [getDocumentName, setGetDocumentName] = useState("");
  const [postDocumentsName, setPostDocumentsName] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  
 const navigate=useNavigate()
  const [searchParam, setSearchParam] = useState(window.location?queryString.parse(window.location.search):props.search)
  useEffect(() => {
    (async () => { 
     await getStarted();  
    })() 
  }, []);

  

  const getStarted=async()=>{  
    
    if(searchParam){
      // console.log(  " searchParam: ", searchParam   );  
      setSearchQuestionText(searchParam.question)
      if(searchParam.question){
          setLoading(true)
         let searchQuestionRes= await postDocuments("searchquestion","/search/question?question="+searchParam.question, {"transactionId":0},"api4")//init user offices and data   
  
      }
        }    
  }

  const getDocumentsAll=async( documentName,relativePath ,additionalData )=>{ 
    setResponseMessage("")
    setGetDocumentName(documentName)
    let responseD={success:false,message:"",record: null};
    let documentsResponse={success:false}  
    ////////// UPDATED/////////////
    documentsResponse=  await HttpService.getService(relativePath,additionalData?additionalData:{},""); 
    // console.log(documentName, " data: ", documentsResponse   );     
    
    if(documentsResponse.success){  
      responseD.success=true;
      responseD.record=documentsResponse.data; 
      responseD.message=documentsResponse.message;  
      let record=responseD.record;
  
      //responseD.record //write conditions down here
  
      if((documentName=="getCatagories")){     
        if(responseD.record){
          // setCatagories(responseD.record.items)  
        }
         }
      if((documentName=="getContentTypes")){    
        if(responseD.record){
        // setContentTypes(responseD.record.items) 
        // getContentTypesSchemas(responseD.record.items) 
        }
         }
      
}
      //  console.log(documentName, " responseD: ", responseD   );   
       setForceRerender(!forceRerender);
       return responseD;
    ////////// UPDATED///////////// 
 
}

const postDocuments=async(documentName,relativePath,data  ,apiType  )=>{   
  setPostDocumentsName(documentName)
  setResponseMessage("")
  let responseD={success:false,message:"",record: null};
  let documentsResponse={success:false}  
  // console.log("getcommonContent,documentsRequest commonContentId",data,relativePath)

  documentsResponse= await HttpService.postService(relativePath,data,{},"" ,apiType); 
  // console.log("getcommonContent,documentsResponse",documentsResponse)
  setLoading(false)
  
  if(documentsResponse.success){  
    responseD.success=true;
    responseD.record=documentsResponse.data; 
    responseD.message=documentsResponse.message;  
    let record = responseD.record
         
  if((documentName=="getCommonContent")){
    // console.log("get commonContent", record)

    if(record.length>0){
      // setCommonContent(record[0])  
      }  
    } 

  if((documentName=="searchquestion")){

    // console.log("record", record.results)
    if(record.results){
    if(record.results.results.length>0){
    // console.log("record.results", record.results.results[0].resultItems)
     setRelatedQuestions(record.results.results[0].resultItems) 
      }  
      else{
        setResponseMessage("No related questions found")
      }
    } }
    } 
    setForceRerender(!forceRerender);
    
      return responseD;
}

 const searchQuestion=async()=>{
  if(searchQuestionText){
    setLoading(true)
    await postDocuments("searchquestion","/search/question?question="+searchQuestionText, {"transactionId":0},"api4")//init user offices and data   
  }
 }

 const goToPage=(page)=>{   
    // let pagePath="/page/"
    //     pagePath+=pageName.replace(" ","")
    //     pagePath+="?pageId="+pageId+"&pageName="+pageName
      // window.location.assign(page);
     
      navigate(page);
    // else{
    //   window.open(page, "_blank")
    // }

  }
  
  return (<>
  
  <MetaTags>
       <title>Knowledge</title>
  </MetaTags>
  <MKBox
        minHeight="20vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      > 
      </MKBox>
    <MKBox component="section" pb={12}>
      <Container>
        {forceRerender}
      <Grid
          container
          item
          justifyContent="center"
          xs={12}
          lg={6}
          sx={{ mx: "auto", pb: 3, textAlign: "center" }}
        > 
          <MKTypography variant="h2" my={1}>
           Type your question below
          </MKTypography> 
        </Grid> 
        <Grid container spacing={3}  
        
            flexDirection="column"
            justifyContent="center"
            alignItems="center">
          <Grid item xs={11} md={8} lg={8}>


          <Paper
              // component="form"
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', maxWidth: 500 }}
            >
              <IconButton sx={{ p: '10px' }} aria-label="menu">
                <Menu />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1,width: 700  }} 
                value={searchQuestionText} 
                placeholder="Type your question here..."
                inputProps={{ 'aria-label': 'Type your question here...' }}
                onChange={(e)=>{
                  // console.log("m",e.target.value); 
                   setSearchQuestionText(e.target.value)}}
              />
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search"  onClick={()=>{searchQuestion()}}>
                <Search/>
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              {/* <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
                <DirectionsIcon />
              </IconButton> */}
            </Paper>




            {/* <MKInput label="Type your question here..." value={searchQuestionText} onChange={(e)=>{console.log("m",e.target.value);  setSearchQuestionText(e.target.value)}}/> */}
            </Grid>
            
            
          {(loading)&&<Grid 
            item
            xs={4}
            lg={4} 
            justifyContent="center" 
          >
            <CircularProgress color="success" />
            </Grid>}
          <Grid item xs={12} md={6} lg={4}>
            <MKButton variant="gradient" color="success" onClick={()=>{searchQuestion()}}>
              search  
            </MKButton>
            </Grid> 
            
          {(!loading&&responseMessage)&&<Grid 
            item
            xs={4}
            lg={4} 
            justifyContent="center" 
          >
          <MKTypography variant="h5" my={1}>
          {responseMessage}
          </MKTypography> 
            </Grid>}
            </Grid> 
      {relatedQuestions&&
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6} my={6}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
            Related Questions
            </MKTypography>
            {/* <MKBox mb={2}>
              <MKTypography variant="body2" align="center" color="text">
                A lot of people don&apos;t appreciate the moment until it&apos;s passed. I&apos;m
                not trying my hardest, and I&apos;m not trying to do
              </MKTypography>
            </MKBox> */}
          </Grid>
          <Grid item xs={12} md={10}>
           {(relatedQuestions.map((articleD,index)=>{

            // console.log("artt",articleD,)
            // title.snippets[0].text
          //   var article = convert.xml2json(articleD.xml, {compact: true, spaces: 4});
          //   article = JSON.parse(article)
          //   console.log("artt", article) 
            if(1){
           return (<FaqCollapse
           
              title={articleD.title.snippets[0].text}
              open={collapse === index}
              onClick={() =>{ 
                goToPage("/pages/Article?answerId="+articleD.globalAnswerId)
                // (collapse === index ? setCollapse(false) : setCollapse(index))
              }}
            > 
              {/* {parse(article[articleD.contentType.referenceKey]["ANSWER"]._cdata)} */}
            </FaqCollapse>)}
          } )) }
          </Grid>
        </Grid>}

      </Container>
    </MKBox>
  </>);
}

export default AskQuestion;
