/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
var convert = require('xml-js');
import parse from 'html-react-parser';
import queryString from "query-string"; 
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// CRM app  React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// HelpCenter page components
import FaqCollapse from "pages/Support/HelpCenter/components/FaqCollapse";
import { HttpService } from "Api-Services/httpService"; 
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import bgImage from "assets/images/ethiopian-collection/aircraft1.jpg";

import { Card, Divider, IconButton, InputBase, Menu, Paper, Skeleton } from "@mui/material";  
import ContentInformation from "./ContentInformation";
import { Stack } from "@mui/system";
import { Search } from "@mui/icons-material";

import { MetaTags } from "react-meta-tags";
import { useLocation, useNavigate } from "react-router-dom";

function SingleArticle(props) {
  const [collapse, setCollapse] = useState(false);
  const [searchParam, setSearchParam] = useState(window.location?queryString.parse(window.location.search):props.search)
  const [forceRerender, setForceRerender] = useState(false);
  const [relatedQuestions, setRelatedQuestions] = useState(null);
  const [currentContent, setCurrentContent] = useState(null);
  const [searchQuestionText, setSearchQuestionText] = useState("");

  const navigate=useNavigate() 
  const { pathname } = useLocation();
  useEffect(() => {
    (async () => { 
     await getStarted();  
    })() 
  }, [pathname]);

  // useEffect(() => {
  //   document.documentElement.scrollTop = 0;
  //   document.scrollingElement.scrollTop = 0;
  // }, [pathname]);
  

  const getStarted=async()=>{    
    if(searchParam){
//  console.log(  " searchParam: ", searchParam   ); 
       await getArticle(searchParam.answerId) 
      }   
    // await getDocumentsAll("getCatagories","/categories?limit=1000",{})//init user offices and data   
    // await getDocumentsAll("getContentTypes","/contentTypes",{})//init user offices and data   
    // await getDocumentsAll("getContentType","/contentTypes/3DC39C3342C84A479A1BF2B25B051947/contentSchema",{})//init user offices and data   
  }
 
  const getArticle=async(articleId)=>{   
    await getDocumentsAll("content","/content/answers/"+articleId,{},"api2")//init user offices and data 
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;  
  }

  const getDocumentsAll=async( documentName,relativePath ,additionalData,apiType )=>{ 
    let responseD={success:false,message:"",record: null};
    let documentsResponse={success:false}  
    ////////// UPDATED/////////////
    documentsResponse=  await HttpService.getService(relativePath,additionalData?additionalData:{},"",apiType); 
    // console.log(documentName, " data: ", documentsResponse   );     
    
    if(documentsResponse.success){  
      responseD.success=true;
      responseD.record=documentsResponse.data; 
      responseD.message=documentsResponse.message;  
      let record=responseD.record;
  
      //responseD.record //write conditions down here
  
      if((documentName=="content")){     
        if(responseD.record){
          let cntDD=responseD.record  
          if(cntDD.xml){
          if(cntDD.title){
            setSearchQuestionText(cntDD.title)
             postDocuments("searchquestion","/search/question?question="+cntDD.title, {"transactionId":0},"api4")//init user offices and data   

          }
          // console.log("cntDD",cntDD)
          let cntD = convert.xml2json(cntDD.xml, {compact: true, spaces: 4});
          cntD = JSON.parse(cntD)
          cntD=Object.entries(cntD[cntDD.contentType.referenceKey])

          setCurrentContent(cntD)  
        }
        }
         }
      if((documentName=="getContentTypes")){    
        if(responseD.record){
        // setContentTypes(responseD.record.items) 
        // getContentTypesSchemas(responseD.record.items) 
        }
         }
      
}
      //  console.log(documentName, " responseD: ", responseD   );   
       setForceRerender(!forceRerender);
       return responseD;
    ////////// UPDATED///////////// 
 
}

const postDocuments=async(documentName,relativePath,data  ,apiType  )=>{   
  let responseD={success:false,message:"",record: null};
  let documentsResponse={success:false}  
  // console.log("getcommonContent,documentsRequest commonContentId",data,relativePath)

  documentsResponse= await HttpService.postService(relativePath,data,{},"" ,apiType); 
  // console.log("getcommonContent,documentsResponse",documentsResponse)
  
  if(documentsResponse.success){  
    responseD.success=true;
    responseD.record=documentsResponse.data; 
    responseD.message=documentsResponse.message;  
    let record = responseD.record
         
  if((documentName=="getCommonContent")){
    // console.log("get commonContent", record)

    if(record.length>0){
      // setCommonContent(record[0])  
      }  
    } 

  if((documentName=="searchquestion")){

    // console.log("record", record.results)
    if(record.results){
    if(record.results.results.length>0){
    // console.log("record.results", record.results.results[0].resultItems)
     setRelatedQuestions(record.results.results[0].resultItems) 
      }  
    } }
    } 
    setForceRerender(!forceRerender);
    
      return responseD;
}

 const searchQuestion=async()=>{
  if(searchQuestionText){

    await postDocuments("searchquestion","/search/question?question="+searchQuestionText, {"transactionId":0},"api4")//init user offices and data   
  }
 }

 const goToPage=(page)=>{   
  // let pagePath="/page/"
  //     pagePath+=pageName.replace(" ","")
  //     pagePath+="?pageId="+pageId+"&pageName="+pageName
    // window.location.assign(page);
    
    navigate(page);
   
  // else{
  //   window.open(page, "_blank")
  // }

}
  return (<>
  
        {forceRerender}
  <MetaTags>
       <title>Article Details</title>
  </MetaTags>

  <MKBox
        minHeight="20vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            {/* <MKTypography
              variant="h2"
              color="white"
              mb={2}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              How can we help you?
            </MKTypography> */}
          </Grid> 
        </Container>
      </MKBox>

  {/* {currentContent && */}
  <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
      { currentContent?<ContentInformation content={currentContent} forceRerender={forceRerender}/> :<>
      <Stack spacing={5}
        sx={{
          p: 5, 
          mt: -8,
          mb: 4, 
        }}>
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="circular" width={70} height={40} />
      <Skeleton variant="rectangular" width={410} height={60} />
      <Skeleton variant="rounded" width={410} height={60} />
    </Stack>
       
       </>
       }
      </Card>
      {/* } */}
    <MKBox component="section" py={12}>
      <Container>
        {forceRerender}
      {relatedQuestions&&
        <Grid container justifyContent="center" 
        sx={{ mx: "auto", pb: 3, textAlign: "center" }}>
          <Grid item xs={12} md={6} my={6}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
            Related Questions
            </MKTypography>
            {/* <MKBox mb={2}>
              <MKTypography variant="body2" align="center" color="text">
                A lot of people don&apos;t appreciate the moment until it&apos;s passed. I&apos;m
                not trying my hardest, and I&apos;m not trying to do
              </MKTypography>
            </MKBox> */}
          </Grid>
          <Grid item xs={12} md={10}>
           {(relatedQuestions.map((articleD,index)=>{

            // console.log("artt",articleD,)
            // title.snippets[0].text
          //   var article = convert.xml2json(articleD.xml, {compact: true, spaces: 4});
          //   article = JSON.parse(article)
          //   console.log("artt", article) 
            if(1){
           return (<FaqCollapse
              title={articleD.title.snippets[0].text}
              open={collapse === index}
              onClick={() => {
                // (collapse === index ? setCollapse(false) : setCollapse(index)) 
                // goToPage("/pages/Article?answerId="+articleD.globalAnswerId)
                getArticle(articleD.globalAnswerId)
              }
              }
            > 
              {/* {parse(article[articleD.contentType.referenceKey]["ANSWER"]._cdata)} */}
            </FaqCollapse>)}
          } )) }
          </Grid>
        </Grid>}
      <Grid
          container
          item
          justifyContent="center"
          xs={12}
          lg={6}
          sx={{ mx: "auto", pb: 3, textAlign: "center" }}
        > 
          <MKTypography variant="h2" my={1}> 
          Don't hesitat to ask any question
          </MKTypography> 
        </Grid> 
        <Grid container spacing={3}  
        
            flexDirection="column"
            justifyContent="center"
            alignItems="center">
          <Grid item xs={11} md={8} lg={8}>
            {/* <MKInput label="Type your question here..." value={searchQuestionText} onChange={(e)=>{console.log("m",e.target.value);  setSearchQuestionText(e.target.value)}}/> */}
           
           

          <Paper
              // component="form"
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', maxWidth: 400 }}
            >
              <IconButton sx={{ p: '10px' }} aria-label="menu">
                <Menu />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                value={searchQuestionText} 
                placeholder="Type your question here..."
                inputProps={{ 'aria-label': 'Type your question here...' }}
                onChange={(e)=>{
                  // console.log("m",e.target.value);  
                  setSearchQuestionText(e.target.value)}}
              />
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search"  
                onClick={()=>{
                  let question=searchQuestionText
                  // searchQuestion()    pagePath+="?pageId="+pageId+"&pageName="+pageName
                window.location.assign("/pages/AskQuestion?question="+question);
                }}
                >
                <Search/>
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              {/* <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
                <DirectionsIcon />
              </IconButton> */}
            </Paper>
             </Grid>
            
          <Grid item xs={12} md={6} lg={4}>
          {/* <Grid
            // container
            item
            xs={4}
            lg={4}
            // flexDirection="column"
            // justifyContent="center"
            // alignItems="center"
            // sx={{ mx: "auto", textAlign: "center" }}
          > */}
            <MKButton variant="gradient" color="success" 
            // onClick={()=>{searchQuestion()}}
            onClick={()=>{
              let question=searchQuestionText
              // searchQuestion()    pagePath+="?pageId="+pageId+"&pageName="+pageName
              navigate("/pages/AskQuestion?question="+question);
            }}
            
            >
              search  
            </MKButton>
            </Grid> 
            </Grid> 
      </Container>
    </MKBox>
  </>
  );
}

export default SingleArticle;
