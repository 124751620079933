 
import HelpCenter from "layouts/pages/support/help-center"; 
import SingleArticle from "pages/Support/HelpCenter/sections/SingleArticle";
import AskQuestion from "pages/Support/HelpCenter/sections/AskQuestion";
import ServiceRequest from "pages/Support/ServiceRequest";
 

const customRoutes = [
  {
    name: "Article",
    route: "/pages/Article",
    component: <SingleArticle />,
  }, 
  {
    name: "Ask Question",
    route: "/pages/AskQuestion",
    component: <AskQuestion />,
  }, 
  {
    name: "Service Request",
    route: "/",
    component: <ServiceRequest />,
  }, 
  {
    name: "Home",
    route: "/home",
    component: <HelpCenter />,
  }, 
  
  
];

export default customRoutes;
