  
import ServiceRequest from "pages/Support/ServiceRequest";
import AskQuestion from "pages/Support/HelpCenter/sections/AskQuestion";

const routes = [
  {
    name: "Knowledge",
    route: "/pages/AskQuestion",
    component: <AskQuestion />,
  },
  
  {
    name: "Service Request",
    route: "/pages/support/service-request",
    component: <ServiceRequest />,
  },
  // {
  //   name: "Support",
  //   icon: <Icon>dashboard</Icon>,
  //   columns: 3,
  //   rowsPerColumn: 2,
  //   collapse: [ 
  //     {
  //       name: "support",
  //       collapse: [
  //         {
  //           name: "help center",
  //           route: "/pages/support/help-center",
  //           component: <HelpCenter />,
  //         },
  //         {
  //           name: "service request",
  //           route: "/pages/support/service-request",
  //           component: <ServiceRequest />,
  //         },
  //         {
  //           name: "contact us",
  //           route: "/pages/support/contact-us",
  //           component: <ContactUs />,
  //         },
  //         {
  //           name: "faq",
  //           route: "/pages/support/faq",
  //           component: <Faq />,
  //         },
  //         // {
  //         //   name: "privacy",
  //         //   route: "/pages/support/privacy",
  //         //   component: <Privacy />,
  //         // },
  //       ],
  //     }, 
  //   ],
  // },
  
];

export default routes;
