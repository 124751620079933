/* 
*/

// @mui material components
import Grid from "@mui/material/Grid";

import { DropzoneArea } from "mui-file-dropzone";
import parse from 'html-react-parser';
// CRM app  React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import "./srStyles.css"
// CRM app  React examples 

// Image
// import bgImage from "assets/images/illustrations/illustration-reset.jpg";
import bgImage from "assets/images/ethiopian-collection/aircraft2.jpg";
import { HttpService } from "Api-Services/httpService";
import React, { useEffect, useState } from "react";
import { AppBar, Autocomplete, Avatar, Box, Button, Card, CardContent, CardHeader, CircularProgress, Container, Divider, FormControl, FormControlLabel, FormLabel, IconButton, LinearProgress, Link, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, ListSubheader, Radio, RadioGroup, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { airportIATACode } from "Api-master-datas/masterDatas"; 
import { Cancel, Delete, Edit, Folder, Home, Save, ViewAgenda, ViewColumn } from "@mui/icons-material";
import SingleRequestDetail from "./singleRequestDetail";
import { red } from "@mui/material/colors";
import { async } from "@firebase/util";
import { valid } from "chroma-js";
import { MetaTags } from "react-meta-tags";
import IframeResizer from "iframe-resizer-react";


// .css-1agvk75 {
//   position: relative;
//   width: 100%;
//   min-height: 250px;
//   background-color: #fff;
//   border: dashed;

function ServiceRequest() {
  const ref = React.useRef();
  const [height, setHeight] = React.useState("500px");
  const onLoad = () => {
    // let newD=ref.current.contentWindow.document.body.scrollHeight + "px"
    // setHeight(newD);
    // console.log("heeeeeeeee",newD)
  };
  const [loading, setLoading] = useState(false);
  const [loadingProcessName, setLoadingProcessName] = useState("");
  const [httpdocumentName, setHttpDocumentName] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [catagories, setCatagories] = useState([]);
  const [selectedCatagories, setSelectedCatagories] = useState([]);
  const [catagoriesDepth2, setCatagoriesDepth2] = useState([]);
  const [Arrival, setArrival] = useState("");
  const [Departure, setDeparture] = useState("");
  const [TicketNumber, setTicketNumber] = useState("");
  const [PNRNumber, setPNRNumber] = useState("");
  const [Title, setTitle] = useState(null);
  const [PrimaryContactEmailAddress, setPrimaryContactEmailAddress] = useState("");
  const [PrimaryContactEmailAddressEditMode, setPrimaryContactEmailAddressEditMode] = useState(false);
  const [StripeCd, setStripeCd] = useState("");
  const [SourceCd, setSourceCd] = useState("");
  const [SalesType, set] = useState("");
  const [ProblemDescription, setProblemDescription] = useState("");
  const [CriticalFlag, setCriticalFlag] = useState("");
  const [CategoryId, setCategoryId] = useState(null);
  const [BaggageReferenceNumber, setBaggageReferenceNumber] = useState("");

  const [forceRerender, setForceRerender] = useState(false);
  const [pageMode, setPageMode] = useState(0);//passengerform 0//createrequest 1||myrequests 2||
  const [pageModeHome, setPageModeHome] = useState(1);//passengerform 0//createrequest 1||myrequests 2||
  const [accountPartyId, setAccountPartyId] = useState("");//createrequest||myrequests
  const [currentServiceRequest, setCurrentServiceRequest] = useState(null)
  const [viewSearchRequestDetail, setViewSearchRequestDetail] = useState(false)
  const [serviceRequests, setServiceRequests] = useState([])

  const [createRequestStepIndex, setCreateRequestStepIndex] = useState(0)
  const [filesArrayOnDropZone,setFilesArrayOnDropZone]=useState([]) 
  const requestSearchFields=[
    {id:1,fieldName:"TicketNumber_c",lable:"Ticket Number"},
    {id:2,fieldName:"SrNumber",lable:"Request Number"},//0000133818
    {fieldName:"PNRNumber_c",lable:"Booking Reference Code"},
    {fieldName:"ShebaMilesNumber_c",lable:"ShebaMiles Number"},
    {fieldName:"BaggageReferenceNumber_c",lable:"Baggage Reference Number"},
    {id:6,fieldName:"AccountPartyId",lable:"Account Party Id"}
  ]////don't change the order , it affects search result

  const passengerDataSearchFields=[
    {id:1,fieldName:"TicketNumber_c",lable:"Ticket Number"},
    {id:2,fieldName:"SrNumber",lable:"Request Number"},//0000133818
    // {id:3,fieldName:"Rloc_c",lable:"Booking Reference Code"},
    // {id:3,fieldName:"PNRNumber_c",lable:"Booking Reference Code"},
    // {id:4,fieldName:"ShebaMilesNumber_c",lable:"ShebaMiles Number"},
    // {id:5,fieldName:"BaggageReferenceNumber_c",lable:"Baggage Reference Number"},
    // {id:6,fieldName:"AccountPartyId",lable:"Account Party Id"}
  ]////don't change the order , it affects search result

  const [requestSearchField, setRequestSearchField] = useState(requestSearchFields[0]);//createrequest||myrequests
  const [passengerDataSearchField, setPassengerDataSearchField] = useState(passengerDataSearchFields[0]);//createrequest||myrequests
  const [requestSearchFieldValue, setRequestSearchFieldValue] = useState("");//createrequest||myrequests
  const [passengerDataSearch, setPassengerDataSearch] = useState({"lastName":"","field1Value":""});//createrequest||myrequests
  const [passengerContactData , setPassengerContactData] = useState(null);//createrequest||myrequests
  const [passengerTicketData  , setPassengerTicketData  ] = useState(null);//createrequest||myrequests

  useEffect(() => {
    onLoad();
  }, []);
  useEffect(() => {
    (async () => { 
     await getStarted();  
    })() 
  }, []);

  

  const getStarted=async()=>{    

    // await getDocumentsAll("content","/content?limit=20&mode=EXTENDED",{})//init user offices and data   
    await getDocumentsAll("getCatagories","/categories?limit=1000",{},"api3")//init user offices and data   
    // await postDocuments("search","/search/question?question=how+do+i+upgrade+my+device", {"transactionId":0},"api4")//init user offices and data    
  }

  const getDocumentsAll=async( documentName,relativePath ,additionalData ,apiType)=>{ 
    let responseD={success:false,message:"",record: null};
    let documentsResponse={success:false}  
  // console.log("getDocumentsAll",documentName,relativePath)
    try {
      
  setLoading(true)   
    setResponseMessage("")
    setHttpDocumentName(documentName)
    ////////// UPDATED/////////////
    documentsResponse=  await HttpService.getService(relativePath,additionalData?additionalData:{},"" ,apiType); 
    // console.log(documentName, " data: ", documentsResponse   );     
    
    if(documentsResponse.success){  
      responseD.success=true;
      responseD.record=documentsResponse.data; 
      responseD.message=documentsResponse.message;  
      let record=responseD.record;
  
      //responseD.record //write conditions down here
  
      if((documentName=="getCatagories")){  
        if(responseD.record){
          let cats=responseD.record.items||[]
          let depth2CatParentValue={}
          setCatagories(cats) 
          cats.forEach(element => {
            if(element.CategoryId	== 300000001775021){
              depth2CatParentValue=element
            }
          }); 
          
          let newSelectedCatRes = filterCatagories(depth2CatParentValue,cats) 
          setCatagoriesDepth2(newSelectedCatRes)
          setForceRerender(!forceRerender);
        }
         }
      if((documentName=="getContentTypes")){    
        if(responseD.record){ 
        }
         }
      if((documentName=="contacts")){    
        if(responseD.record){  
            if(responseD.record.items){
              if(responseD.record.items.length){
                responseD.record=responseD.record.items
              } 
            } 
        }
         }
      if((documentName=="getserviceRequests")){    
        if(responseD.record){  
            if(responseD.record.items){
              if(responseD.record.items.length){
                responseD.record=responseD.record.items
              } 
            } 
        }
         }
      
}

else if(documentsResponse.error){
  // console.log("getcommonContent,error",documentsResponse.error)
  if((documentsResponse.error.response)){
  let errD=documentsResponse.error.response
    setResponseMessage(errD.data)// console.log("get commonContent", record) 
    } 

  } 
  setForceRerender(!forceRerender);
  setLoading(false)   
} catch (error) {
  
}
  return responseD;
    ////////// UPDATED///////////// 
 
}


const postDocuments=async(documentName,relativePath,data  ,apiType  )=>{
  let responseD={success:false,message:"",record: null};
  try {
  setLoading(true)   
    setHttpDocumentName(documentName)
    setResponseMessage("")
  let documentsResponse={success:false}  
  // console.log("postDocuments ", documentName ,data,relativePath)

  documentsResponse= await HttpService.postService(relativePath,data,{},"" ,apiType); 
  // console.log("getcommonContent,documentsResponse",documentsResponse)
  
  if(documentsResponse.success){  
    responseD.success=true;
    responseD.record=documentsResponse.data; 
    responseD.message=documentsResponse.message;  
    let record=responseD.record;

    //responseD.record //write conditions down here

    if((documentName=="getCatagories")){     
      if(responseD.record){
        let cats=responseD.record.items||[]
        let catsD2=[]
        setCatagories(cats) 
        cats.forEach(element => {
          if(element.Depth==2){
            catsD2.push(element)
          }
        }); 
            setCatagoriesDepth2(catsD2)
      }
    }
         
  if((documentName=="getCommonContent")){
    // console.log("get commonContent", record)

    if(record.length>0){
      // setCommonContent(record[0])  
      }  
    } 
  if((documentName=="serviceRequests")){
    // console.log("get commonContent", record)  
    setResponseMessage("Service Requests created")// console.log("get commonContent", record) 
    
    } 
    } 

  else if(documentsResponse.error){
    // console.log("getcommonContent,error",documentsResponse.error)
    if((documentsResponse.error.response)){
    let errD=documentsResponse.error.response
      setResponseMessage(errD.data)// console.log("get commonContent", record) 
      } 

    } 
    setForceRerender(!forceRerender);
    setLoading(false)   
     
    
  } catch (error) {
    
  }
      return responseD;
}

 
const patchDocuments=async(documentName,relativePath,data  ,apiType  )=>{
  let responseD={success:false,message:"",record: null};
  try {
  setLoading(true)   
  setResponseMessage("")
  let documentsResponse={success:false}  
//   console.log("getcommonContent,documentsRequest commonContentId",data,relativePath)
  documentsResponse= await HttpService.patchService(relativePath,data,{},"" ,apiType); 
  // console.log("getcommonContent,documentsResponse",documentsResponse)
  
  if(documentsResponse.success){  
    responseD.success=true;
    responseD.record=documentsResponse.data; 
    responseD.message=documentsResponse.message;  
    let record=responseD.record;

    //responseD.record //write conditions down here
  
  if((documentName=="serviceRequests")){
    // console.log("get commonContent", record)  
    setResponseMessage("Service Requests created")// console.log("get commonContent", record) 
    
    } 
  if((documentName=="updateContact")){ 
    } 
    } 

  else if(documentsResponse.error){
    // console.log("getcommonContent,error",documentsResponse.error)
    if((documentsResponse.error.response)){
    let errD=documentsResponse.error.response
      setResponseMessage(errD.data)// console.log("get commonContent", record) 
      } 

    } 
    setForceRerender(!forceRerender);
    setLoading(false)   
     
    
  } catch (error) {
    
  }
      return responseD;
}

const filterCatagories=(cat,allCatagoriesD )=>{ 
        // console.log("cat, ",cat)
          let newSelectedCat=[]
        try {
          
          let allCatagories=catagories 
          if(allCatagoriesD){
            allCatagories=allCatagoriesD
          }
          if(allCatagories){
            allCatagories.forEach(element => {
            // if((element.Hierarchy.includes(cat.CategoryName))){
            if((element.ParentCategoryId==cat.CategoryId)){
              newSelectedCat.push(element)
            }
          }); 
         }
        } catch (error) {
          
        }
         return newSelectedCat
}

const SubmitRequest=async()=>{
        // console.log("data",data)
        // let contactData={}  
        // let contactPartyId=null
        let accountPartyId=null
        let searchField={};
        let searchFieldValue="";
        let created_SRNumber;
        let categoryId= CategoryId?CategoryId.CategoryId:null
        let title=  Title?Title.CategoryName:null
try {
  
        let data= {
          // "FirstName":contactData.FirstName,//, "Winta Haile",
          // "LastName":contactData.LastName,//, "Gebre",
          // "EmailAddress":contactData.EmailAddress,//, "meetwinijesus@gmail.com",
          // "AccountPartyId":accountPartyId,// 300000001773011 ,
          "PrimaryContactPartyId": passengerContactData.PartyId,//300000230893864 ,
          // "PrimaryContactEmailAddress": PrimaryContactEmailAddress,//300000230893864 ,
          "ReportedByPartyId": passengerContactData.PartyId,//300000230893864 ,
          // "Departure_c":Departure,// "DXB",
          // "Arrival_c": Arrival,// "XGZ",
          // "BaggageReferenceNumber_c": BaggageReferenceNumber,//,"232323232",
          "CategoryId":categoryId,//,catag 300000001774982,
          "CriticalFlag": false,
          // "PNRNumber_c":PNRNumber,// "232323",//Booking reference code 
          "ProblemDescription" :ProblemDescription,//  "description",
          "SalesType_c" :  "PASSENGER",
          "SourceCd" :  "ORA_SVC_CUSTOMER_UI",
          "StripeCd"  :  "ORA_SVC_CRM",
          // "TicketNumber_c" :(passengerTicketData.TicketNumber_c)?passengerTicketData.TicketNumber_c:(0+passengerTicketData.RecordName),//  "23232323",
          // "Ticket_c" : (passengerTicketData.TicketNumber_c)?passengerTicketData.TicketNumber_c:(0+passengerTicketData.RecordName),//  "23232323",
          "Title" :  title,// "Baggage Delivery",
        }
        if(passengerTicketData.TicketNumber_c){
          data.TicketNumber_c=passengerTicketData.TicketNumber_c
          data.Ticket_c=passengerTicketData.TicketNumber_c
        }
        else if(passengerTicketData.RecordName){
          data.TicketNumber_c=passengerTicketData.RecordName
          data.Ticket_c=passengerTicketData.RecordName
        }
        if(Arrival!=""){
          data.Arrival_c= Arrival// "XGZ",
        }
        if(Departure!=""){
          data.Departure_c= Departure// "XGZ",
        }
        if(passengerTicketData){
          data.IataNumber_c= passengerTicketData.IssuingAgentIATACode_c// "XGZ",
          data.TourCode_c= passengerTicketData.TourCode_c// "XGZ",
        }
        // console.log("postserviceRequests data",data)
        let validationRes=validateForm(data,["ProblemDescription","Title","CategoryId"]) 
        if(!(validationRes.valid)){  
         setResponseMessage("Required fields (*) are not filled correctly")
         return
        }

          
        if(!(passengerContactData.EmailAddress)){ 
            if(PrimaryContactEmailAddress==""){ 
                setResponseMessage("Email is required, please enter your prefered email!")
                return
            }
          }
           
        
        if(PrimaryContactEmailAddress){ 
          if(PrimaryContactEmailAddress!=passengerContactData.EmailAddress){
             if(verifyEmail(PrimaryContactEmailAddress)){
                setLoading(true)   
                setLoadingProcessName("creatingservicerequest")
                await updatePassengerContactData({"EmailAddress":PrimaryContactEmailAddress }) 
                setLoading(false)   
             }
             else{ 
                setResponseMessage("Email is not valid, please enter correct email!")
                return
             }

            }  
          }

      //CREATE  SR
      setLoading(true)   
      setLoadingProcessName("creatingservicerequest")
      let serviceRequestsRes= await postDocuments("postserviceRequests","/serviceRequests", data,"api3")//init user offices and data    
      // console.log("postserviceRequests ",serviceRequestsRes)
      setLoadingProcessName("")

      if(serviceRequestsRes.success){
        if(serviceRequestsRes.record){
        let record=serviceRequestsRes.record
        
          setPageMode(2)
          setCurrentServiceRequest(record)
          setViewSearchRequestDetail(true);
          searchField=requestSearchFields[1];
          searchFieldValue=record.SrNumber;
          created_SRNumber=record.SrNumber;
        if(record.AccountPartyId) {  
            searchFieldValue=record.AccountPartyId
            searchField=requestSearchFields[5];
            setAccountPartyId(accountPartyId) 
         }
        //  if(PrimaryContactEmailAddress){ 
        //    if(PrimaryContactEmailAddress!=passengerContactData.EmailAddress){
        //      updatePassengerContactData({"EmailAddress":PrimaryContactEmailAddress }) 
        //      }  
        //    }
           if(filesArrayOnDropZone){
            handleSave(record.SrNumber)
           }
            setRequestSearchField(searchField)
            setRequestSearchFieldValue(searchFieldValue)
            getServiceRequests(searchField,searchFieldValue)
        } 
      } 
      else{ 
        return
      }
        
    } catch (error) {
      setLoading(false)   
      setLoadingProcessName("creatingservicerequest")
      setResponseMessage("Error occured, please try again!")
      return
    } 

 
}

const verifyEmail = (value) => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};

const validateForm= (formData,requiredFields) =>{  
  let valid=true;
  try {
    if(formData&&requiredFields){ 
      for(let i=0;i<requiredFields.length;i++){
        if((formData[requiredFields[i]])){
        }
        else{
          valid=false
        }  
        } 
    } 
  } catch (error) {
    
  }
  let response={"valid":valid,"message":""}
  return response
}

const sendAttachment=async(attachmentType,dataD,SrNumber)=>{
  let data=dataD
  let serviceRequestsRes={success:false,record:null,message:""}
  try {
     
  if(attachmentType=="WEB_PAGE"){
    data= {
      "Url": Attachment.Url,
      "DatatypeCode": "WEB_PAGE",
      // "Title": "REST API Attachment",
       "Description": Attachment.description
      }

  }
  //  console.log(data,"sendAttachment")
      serviceRequestsRes= await postDocuments("postserviceRequests","/serviceRequests/"+SrNumber+"/child/Attachment", data,"api3")//init user offices and data    
    // console.log("postserviceRequests Attachment",serviceRequestsRes)
    if(serviceRequestsRes.success){ 
      if(serviceRequestsRes.record){
      let record=serviceRequestsRes.record 
          // setRequestSearchField(searchField)
          // setRequestSearchFieldValue(searchFieldValue)
          // getSingleServiceReuest(searchField,searchFieldValue)
      } 
    } 
  } catch (error) {
    
  }
    return serviceRequestsRes
}

const handleSave=async (SrNumber) =>{
  // console.log("filesArrayOnDropZone",filesArrayOnDropZone) 
  if(filesArrayOnDropZone&&SrNumber){
    let filesArray=filesArrayOnDropZone
    for(let ff=0;ff<filesArray.length;ff++){

      let respp=await sendAttachment("file",filesArray[ff],SrNumber) 
      // console.log("sendAttachment respp",respp)
      } 
  }
}
const handleChange=async (filesData) =>{
     try{
      // console.log("handleChange filesData",filesData)
      var filesArrayOnDropZoneD=[];  
      for(let f=0;f<filesData.length;f++){
      // let file  = 
      var fileD =  filesData[f];   // FileList object 
      var reader = new FileReader(); 
      reader.onload =   (function(theFile) {
        return function(e) {
          var binaryData = e.target.result; 
          var base64String = window.btoa(binaryData);   
          filesArrayOnDropZoneD.push({
            CategoryName: "MISC",
            DatatypeCode: "FILE",
            Description: "",
            FileContents:base64String ,//,attachmentFile//"UEsDBBQABgAIAAAAIQDCo+7qtQEAADYJAAATAAgCW0NvbnRlb
            FileName: filesData[f].name,
            UploadedFileContentType:filesData[f].type //"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" //"//"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        })
        // console.log("filesArrayOnDropZoneD reqqq",filesArrayOnDropZoneD)
        setFilesArrayOnDropZone(filesArrayOnDropZoneD)
        };
      })(fileD); 
      reader.readAsBinaryString(fileD); 
      }  
              
    }
    catch(err){}
    }
 

const updatePassengerContactData=async(data)=>{
  // console.log("updatePassengerContactData",data)
  setLoadingProcessName("updatingcontactinformation")
  try {
    
    if(passengerContactData){
      let relativePathContact="/contacts/"+passengerContactData.PartyNumber//+"&q=LastName="+passengerDataSearch.lastName
      let contactRes= await patchDocuments("updateContact",relativePathContact,data,"api3")//init user offices and data  
      setLoadingProcessName("")
      // console.log("contactRes contactPartyId",contactRes)
      if(contactRes.success){
        if(contactRes.record){ 
            setPassengerContactData(contactRes.record)
            setForceRerender(!forceRerender);
        } 
      } 
    }
  } catch (error) {
    
  }

}
 
const getPassengerData=async()=>{
        // console.log("getPassengerData ")

  try {
    
  setServiceRequests([])
  setCurrentServiceRequest(null)
  setPassengerContactData(null)

  setLoadingProcessName("gettingtorequestspage")
  setLoading(true)    
      let contactPartyId=null 
      let TicketNumber_c=null
      let contactData=null 
      setResponseMessage("")// console.log("get commonContent", record) 
      
      if(passengerDataSearchField.fieldName=="SrNumber"){//"0000136835"
        //GET SR DATA"0000136835"
        let relativePathserviceRequests="/serviceRequests?onlyData=true&totalResults=true&q="+passengerDataSearchField.fieldName+"="+passengerDataSearch.field1Value
        let serviceRequestsRes= await getDocumentsAll("getserviceRequests", relativePathserviceRequests,  {},"api3")//init user offices and data    
        // console.log("getserviceRequests res",serviceRequestsRes) 
        if(serviceRequestsRes.success){
          if(serviceRequestsRes.record){ 
              if(serviceRequestsRes.record.length){
                let serviceRequestsDDD=serviceRequestsRes.record 
                contactPartyId=serviceRequestsDDD[0].PrimaryContactPartyId
                TicketNumber_c=serviceRequestsDDD[0].TicketNumber_c
                setCurrentServiceRequest(serviceRequestsDDD[0])
                setViewSearchRequestDetail(true);
              }}} 
        if(!contactPartyId){
          setResponseMessage("Service request data not found, please try again")// console.log("get commonContent", record) 
          return
        }
      }
      // console.log("TicketNumber_c ",TicketNumber_c)

        if(TicketNumber_c){
        //GET TICKET DATA by ticket number   
        let relativePathTicket="/Ticket_c?onlyData=true&totalResults=true&q=TicketNumber_c="+TicketNumber_c
        let ticketRes= {}  
        ticketRes= await getDocumentsAll("getTicket",relativePathTicket,{},"api3")//init user offices and data    
        // console.log("tichet ",ticketRes)

         
        // console.log("ticketRes.record ",ticketRes)
        if(ticketRes.success){
          if(ticketRes.record){
        // console.log("ticketRes.record ",ticketRes.record)

            let countReco=ticketRes.record.count||0
            if(countReco>0){ 
             ticketRes.record.items.forEach((ticketItem)=>{
                  if(ticketItem.Person_Id_Contact_To_Ticket){
                    setPassengerTicketData(ticketItem)
                    contactPartyId=ticketItem.Person_Id_Contact_To_Ticket 
                  }
               }
              )
            }
            else{
              try {
                let RecordName=toString(TicketNumber_c)
                if(RecordName[0]=="0"){
                 RecordName=RecordName.slice(1)+""
                } 
                let relativePathTicket2="/Ticket_c?onlyData=true&totalResults=true&q=RecordName="+RecordName
                ticketRes= await getDocumentsAll("getTicket",relativePathTicket2,{},"api3")//init user offices and data   
                if(ticketRes.success){
                  if(ticketRes.record){
                    if(ticketRes.record.items){ 
                    ticketRes.record.items.forEach((ticketItem)=>{
                          if(ticketItem.Person_Id_Contact_To_Ticket){
                            setPassengerTicketData(ticketItem)
                            contactPartyId=ticketItem.Person_Id_Contact_To_Ticket 
                          }
                      }
                      )
                    }
                  }
                }
              } catch (error) {
              } 

            }
          } 
        }
        else{
         setResponseMessage("Ticket is not correct")// console.log("get commonContent", record) 
          return
        }
      }
      else{
        //GET TICKET DATA"0000136835"
        let relativePathTicket="/Ticket_c?onlyData=true&totalResults=true&q="+passengerDataSearchField.fieldName+"="+passengerDataSearch.field1Value
        let ticketRes= await getDocumentsAll("getTicket",relativePathTicket,{},"api3")//init user offices and data    
        // console.log("tichet ",ticketRes)
        if(ticketRes.success){
          if(ticketRes.record){
            if(ticketRes.record.items){ 
              if(ticketRes.record.items.length){ 
              ticketRes.record.items.forEach((ticketItem)=>{
                  if(ticketItem.Person_Id_Contact_To_Ticket){
                    setPassengerTicketData(ticketItem)
                      contactPartyId=ticketItem.Person_Id_Contact_To_Ticket 
                  }
                }
              )
            }
            else if(passengerDataSearchField.fieldName=="TicketNumber_c") {
             try {
               let RecordName=passengerDataSearch.field1Value.toString()
               if(RecordName[0]=="0"){
                RecordName=RecordName.slice(1)+""
               } 
               let relativePathTicket2="/Ticket_c?onlyData=true&totalResults=true&q=RecordName="+RecordName
               ticketRes= await getDocumentsAll("getTicket",relativePathTicket2,{},"api3")//init user offices and data   
               if(ticketRes.success){
                 if(ticketRes.record){
                   if(ticketRes.record.items){ 
                     if(ticketRes.record.items.length){ 
                     ticketRes.record.items.forEach((ticketItem)=>{
                         if(ticketItem.Person_Id_Contact_To_Ticket){
                           setPassengerTicketData(ticketItem)
                             contactPartyId=ticketItem.Person_Id_Contact_To_Ticket 
                         }
                       }
                     )
                   }
               else{
                 setResponseMessage("The ticket you put is not correct, contact ticket agent")// console.log("get commonContent", record) 
                 return
                 }
                }  
                 }
               }
               
             } catch (error) {
             } 
             // setResponseMessage("The ticket you put is not correct")// console.log("get commonContent", record) 
             //  return
   
           } 
        else{
          setResponseMessage("The ticket you put is not registered, contact ticket agent")// console.log("get commonContent", record) 
          return
          }
         }  
        else{
          setResponseMessage("The ticket you put is not correct")// console.log("get commonContent", record) 
           return
         }
          } 
        }
        else{
         setResponseMessage("The ticket you put is not correct")// console.log("get commonContent", record) 
          return
        }

      }
 
        let relativePathContact="/contacts?q=PartyId="+contactPartyId//+"&q=LastName="+passengerDataSearch.lastName 
        if(contactPartyId){
        let contactRes= await getDocumentsAll("contacts",relativePathContact,{},"api3")//init user offices and data  
        // console.log("contactRes contactPartyId",contactPartyId,contactRes)
        if(contactRes.success){
          if(contactRes.record){ 
              if(contactRes.record.length){
              contactData=contactRes.record[0]
              }  
          } 
        } 

        }  
        else{
         setResponseMessage("ticket contact address not found, please contact ticket office")// console.log("get commonContent", record) 
         return
        }
        if(!contactData){
         setResponseMessage("ticket contact address not found, please contact ticket office")// console.log("get commonContent", record) 
         return
      }
       
        if(contactData) {   
          
          // if((passengerDataSearch.lastName)&&(contactData.LastName)){
            // if(passengerDataSearch.lastName.toLowerCase()==contactData.LastName.toLowerCase()){
            setPassengerContactData(contactData)
          //  setPrimaryContactEmailAddress()//NICKESALLNAS
            // setCreateRequestStepIndex(1)
            //  console.log("contact Data ",contactData)
             
              setPageMode(pageModeHome) 
              setRequestSearchField({fieldName:"PrimaryContactPartyId"})
              setRequestSearchFieldValue(contactData.PartyId)
              getServiceRequests({fieldName:"PrimaryContactPartyId"},contactData.PartyId)  
       }
  setLoadingProcessName("")

  let passengerDataSearchD=passengerDataSearch
  passengerDataSearchD.field1Value="" 
  setPassengerDataSearch(passengerDataSearchD)
  setForceRerender(!forceRerender)

} catch (error) {
    
}
}
 

const parseDate=(oldD)=>{
  let d= new Date(oldD);
  let hours= d.getHours().toString()
  let minutes=   d.getMinutes().toString() 
  let seconds= d.getSeconds().toString()

    if(hours.length<2){
      hours="0"+hours
    }
    if(minutes.length<2){
      minutes="0"+minutes
    }
    if(seconds.length<2){
      seconds="0"+seconds
    }

  let fDate=(d.getMonth()+1)+"/"+d.getDate()+"/"+d.getFullYear() +" , "+hours + ":" +minutes+ ":" + seconds;
  return fDate;
  }
  

const hidePartialCharacters=(wordsData)=>{ 
  try {
    
  let finalString=""
  let emailDomain=""
  if(wordsData){
    if(wordsData.length){

  let wordsD=wordsData.toString()
  if((wordsD.indexOf("@")>-1)&&(wordsD.indexOf(".")>-1)){
  let indexofChar=wordsD.indexOf("@");
  emailDomain=wordsD.substr(indexofChar,wordsD.length)
  wordsD=wordsD.substr(0, indexofChar)
  }

  let words = wordsD.split(' ');
  // console.log(words[3]);
  words.forEach(stringD => { 
    let hideLength=parseInt(stringD.length/3)
    if(hideLength%2!=0){
      hideLength=hideLength+1
    }
    // parseInt() Method
    let astrixChar=""
    let selectedChar=stringD.substr(0, hideLength)
      for(let s=0;s<(stringD.length-hideLength);s++){ 
        astrixChar=astrixChar+"*"
      }
      let newString=selectedChar+astrixChar
      finalString=finalString+" "+newString
    });
   }
  }
    return finalString+""+emailDomain
  } catch (error) {
    
  }
  }
  
    
const getServiceRequests=async(fieldNameD,valueD)=>{
  try {
    
  setLoading(true)   
  let serviceRequestsDDD=[]
  // console.log("getServiceRequests ",fieldNameD,valueD) 

  let fieldName,value
  let searchMethod=""
  if(fieldNameD&&valueD){
    searchMethod="searchinput"
    fieldName=fieldNameD.fieldName 
    value=valueD 
  }
  else{
    fieldName= requestSearchField.fieldName
    value= requestSearchFieldValue
  }

  // let serviceRequestsRes= await getDocumentsAll("serviceRequests/"+ requestSearchFieldValue,"/serviceRequests", {},"api3")//init user offices and data 
  let serviceRequestsRes= await getDocumentsAll("getserviceRequests","/serviceRequests/?q="+fieldName+"="+ value, {},"api3")//init user offices and data    
  // console.log("getserviceRequests res",serviceRequestsRes) 
  if(serviceRequestsRes.success){
    if(serviceRequestsRes.record){ 
        if(serviceRequestsRes.record.length){
         serviceRequestsDDD=serviceRequestsRes.record 
          let searchFieldValue, searchField; 
          setServiceRequests(serviceRequestsDDD)
      }
    } 
  }
  // console.log("serviceRequestsDDD  ",serviceRequestsDDD) 
  // let pageModeD=pageModeHome||2
  // setPageMode(pageModeD)
  } catch (error) {
    
  }
}

const createRequestForm=()=>{

return <>

<MKBox width="100%" component="form" method="post" autocomplete="off">
 
              { createRequestFormStem2()}
              </MKBox>




</>

}

const passengerContactDetails=()=>{

return <>

{passengerContactData&&<>
     <Card  sx={{ width:"100%"  }}>
      {/* <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            p
          </Avatar>
        }
        action={
          <IconButton aria-label="settings"> 
              
                {( passengerContactData.StatusCd!= "ORA_SVC_RESOLVED")&&
                <MKButton variant="gradient" color="warning" size="small" 
                onClick={()=>{
                    updateSRStatus("ORA_SVC_RESOLVED")//ORA_SVC_CLOSED
                }}
                 > finish sr
                </MKButton> }
                {(passengerContactData.StatusCd== "ORA_SVC_RESOLVED")&&
                <MKButton variant="gradient" color="warning" size="small" 
                    // onClick={()=>{updateSRStatus("ORA_SVC_NEW")}}
                  > re-open sr
                </MKButton> }
          </IconButton>
        }
        title={passengerContactData.Title}
        subheader={passengerContactData.StatusTypeCdMeaning}
      />  */}
      <CardContent> 

<Grid container  item xs={12}  > 
 
  
<Grid item lg={6} md={6}>  

 <List 
      sx={{ width: '100%',   bgcolor: 'background.paper' }}
      component="nav"
    //   aria-labelledby="nested-list-subheader"
    //   subheader={
    //     <ListSubheader component="div" id="nested-list-subheader">
    //         Request Detail
    //     </ListSubheader>
    //   }
      > 
      <ListItem > 
          <ListItemText
          
          primary={"Contact Name"}
          secondary= {
            <Typography variant="body2" color="text.secondary">
             {hidePartialCharacters(passengerContactData.ContactUniqueName)}
           </Typography>   } 
          />
        </ListItem> 
        <ListItem > 
            <ListItemText
            
            primary={"Contact Email"} 
              secondary= {<>  
              
              <Typography variant="body2" color="text.secondary">
                  {hidePartialCharacters(passengerContactData.EmailAddress)}
              </Typography>
              { (PrimaryContactEmailAddressEditMode)&&
                  <MKInput
                  type="email"
                  value={PrimaryContactEmailAddress}
                  label="Email *"
                  placeholder="Enter new email here"
                  onChange={(e)=>{setPrimaryContactEmailAddress(e.target.value)}}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />  
              }
              <span>
              {(PrimaryContactEmailAddressEditMode)?
              <><Button  onClick={()=>{
                
                updatePassengerContactData({"EmailAddress":PrimaryContactEmailAddress }) 
                setPrimaryContactEmailAddressEditMode(false)}} color="success" startIcon={<Save />}>
                save
              </Button>
              <Button  onClick={()=>{setPrimaryContactEmailAddressEditMode(false)}} color="success" startIcon={<Cancel />}>
                cancel
              </Button>
              </>: 
              <Button  onClick={()=>{setPrimaryContactEmailAddressEditMode(true)}} color="success" startIcon={<Edit />}>
                Edit
              </Button>}
              {/* <Edit fontSize="small" /> */}
              </span>  
              </>}
            />
            
          </ListItem> 
        </List>
    <Divider orientation="vertical" flexItem />
      </Grid>
      

  <Grid item lg={5} md={5}> 
      
<List 
      sx={{ width: '100%',   bgcolor: 'background.paper' }}
      component="nav"
    //   aria-labelledby="nested-list-subheader"
    //   subheader={
    //     <ListSubheader component="div" id="nested-list-subheader">
    //         Request Detail
    //     </ListSubheader>
    //   }
      > 
       {passengerTicketData&&
        <ListItem  onClick={()=>{   }}   > 
          <ListItemText
          
          primary={"Ticket Number"}
          secondary= {
            <Typography variant="body2" color="text.secondary">
              {passengerTicketData.TicketNumber_c||passengerTicketData.RecordName}
           </Typography>   } 
          // primary={<small>Ticket Number</small>}
          // secondary= {<h3><strong>{passengerTicketData.TicketNumber_c}</strong></h3>}  
          />
        </ListItem>  }
        </List>
        <Divider /> 

</Grid>     
</Grid>
{/* 
<Grid width='100%'   container item justifyContent="left" xs={12} lg={12} mx="auto"   mb={2}   mt={2}>

        <Typography variant="body2" color="text.secondary">
         {passengerContactData.ProblemDescription}
        </Typography>

</Grid>  */}  
 

                {/* <Grid container item justifyContent="left" xs={12} mt={5} mb={2}>
                </Grid>  */}

  


      </CardContent>

    </Card>
</>}



</>

}

const searchPassengerContactForm=()=>{

return <>

       <Grid container spacing={3}>  
       
              <Grid item xs={12}>  
              <MKBox width="100%" component="form" method="post" autocomplete="off" 
                  // mt={{ xs: 2, sm: 1, md: 2 }}
                  mb={{ xs: 1, sm:1, md: 1 }}
                  >
                <AppBar position="static">
                  <Tabs value={pageModeHome} indicatorColor='primary' 
                  onChange={(event, newValue) => {
                    setPageModeHome(newValue); 
                    setPassengerDataSearchField(passengerDataSearchFields[0])
                    setForceRerender(!forceRerender)
                    }}>
                    <Tab label="Create New Request" color= "success"  value={1} />
                    <Tab label="Manage Existing Requests" color= "success"   value={2} />
                  </Tabs>
                </AppBar>  
              </MKBox>
              </Grid>
              <Grid item xs={12}>  
                 <FormControl>
                  {/* <FormLabel id="demo-row-radio-buttons-group-label">Get my data by</FormLabel> */}
              { (pageModeHome!=1)?
                  <RadioGroup
                    row
                    color="secondary"
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group" 
                    value={passengerDataSearchField?passengerDataSearchField.id:null}
                    onChange={(event)=>{ 
                      let passengerDataSearchFieldsD=passengerDataSearchFields
                      passengerDataSearchFieldsD.forEach(element => {
                        if(element.id==event.target.value){
                          setPassengerDataSearchField(element); 
                        }
                      });
                    }}
                  >
                    {passengerDataSearchFields&&<>
                    {passengerDataSearchFields.map((option,index)=><>
                    {/* {((pageModeHome!=1)?true:(option.id!=2)?true:false)&&<FormControlLabel value={option.id} control={<Radio />} label={option.lable}  />} */}
                    <FormControlLabel value={option.id} control={<Radio />} label={option.lable}  />
                    </>
                    )}
                     </>}
                  </RadioGroup>:
                  <FormLabel id="demo-row-radio-buttons-group-label">Enter your Ticket Number </FormLabel>
                  }
                </FormControl> 
               </Grid> 
               {/* <Grid item xs={12}>  
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      value={passengerDataSearchField}
                      options={passengerDataSearchFields}
                      getOptionLabel={(option) => option.lable}
                      onChange={(m,value)=>{
                        setPassengerDataSearchField(value ) 
                      }}
                      // sx={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label="Get my data by *" />}
                    /> 
                  </Grid> 
                   */}
                {passengerDataSearchField &&
                <Grid item xs={12} lg={12} md={12}>
                    <MKInput
                      type="text"
                      // variant="standard" 
                    color="secondary"
                      value={passengerDataSearch.field1Value}
                      label={passengerDataSearchField.lable +" *"}
                      onChange={(e)=>{
                        let passengerDataSearchD=passengerDataSearch
                        passengerDataSearchD.field1Value=e.target.value
                        // console.log("filedValue",passengerDataSearchD)
                        setPassengerDataSearch(passengerDataSearchD)
                        setForceRerender(!forceRerender)}}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>  } 
                  
                {/* <Grid item xs={12} lg={12} md={12}>
                    <MKInput
                      type="text"
                      // variant="standard"
                      value={passengerDataSearch.lastName}
                      label={ "Last Name *"}
                      onChange={(e)=>{
                        let passengerDataSearchD=passengerDataSearch
                        passengerDataSearchD.lastName=e.target.value
                        // console.log("lastName",passengerDataSearchD)
                        setPassengerDataSearch(passengerDataSearchD)
                        setForceRerender(!forceRerender)
                      }}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>    */}
          </Grid>

              
          <Grid container item justifyContent="center" xs={12} mt={5} mb={2} >
                        {(loading&&(loadingProcessName=="gettingtorequestspage"))&& 
                        <CircularProgress /> }
                        
                        {responseMessage&&<MKTypography variant="body2" color="warning">
                        { responseMessage }
                        </MKTypography>}
                 </Grid>
                <Grid container item justifyContent="center" xs={12} mt={2} mb={2}>
                  <MKButton variant="gradient" color="warning" size='large' disabled={(passengerDataSearch?(passengerDataSearch.field1Value?false:true):true)}
                      onClick={()=>{getPassengerData()}}
                      >
                    Search
                  </MKButton> 
                </Grid>
                <Grid container item justifyContent="center" xs={12} mb={2}> 
                  
                  <Link href="https://www.ethiopianairlines.com/aa/en/support-and-feedback" underline="hover" target={"_blank"} variant="body2">
                    {"I don't have ticket number"}
                  </Link>
                </Grid>
</> 
}

const createRequestFormStem2=()=>{

  return <>
  
  <Grid container spacing={3}   mt={2}> 
                 {/* <Grid item xs={12} lg={6} md={6}>
                    <MKInput
                      type="text"
                      // variant="standard"
                      label="First Name *"
                      onChange={(e)=>{setPNRNumber(e.target.value)}}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}  lg={6} md={6}>
                    <MKInput
                      type="text"
                      // variant="standard"
                      label="Last Name *" 
                      onChange={(e)=>{setPNRNumber(e.target.value)}}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid> */}
                  {(catagories)&&<Grid item xs={12}>  
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      value={Title||null}
                      options={catagoriesDepth2}
                      getOptionLabel={(option) => option.CategoryName}
                      onChange={(m,value)=>{
                        // setTitle(value.CategoryName )
                        setTitle(value )
                        setCategoryId(null )
                        if(value){ 
                        let catRes=filterCatagories(value)
                        setSelectedCatagories(catRes)  
                        setForceRerender(!forceRerender);}
                      }}
                      // sx={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label="Title / Subject *" />}
                    /> 
                  </Grid>}

                  {(catagories)&&<Grid item xs={12}>  
                    <Autocomplete
                      disabled={Title?false:true}
                      disablePortal
                      id="combo-box-demo"
                      value={CategoryId||null}
                      options={selectedCatagories}
                      getOptionLabel={(option) => option.CategoryName}
                      // onChange={(e,value)=>{setCategoryId(value.CategoryId )}}
                      onChange={(e,value)=>{
                        if(value){ 
                        setCategoryId(value )
                        }
                      }}
                      // sx={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label="Category *" />}
                    />


                  </Grid>}
                  {/* <Grid item xs={12} >
                    <MKInput
                      type="text"
                      // variant="standard"
                      label="Booking Reference Code (PNR)*"
                      onChange={(e)=>{setPNRNumber(e.target.value)}}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid> */}
                  {/* <Grid item xs={12} >
                    <MKInput type="number" 
                      // variant="standard"
                      onChange={(e)=>{setTicketNumber(e.target.value)}}
                      label="Ticket Number *  "
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid> */}
                  
                  {/* <Grid item xs={12} >
                    <MKInput type="number" 
                      // variant="standard"
                      onChange={(e)=>{setTicketNumber(e.target.value)}}
                      label="ShebaMiles Number  "
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid> */}
                  {airportIATACode&&
                  <Grid item xs={12}  lg={6} md={6}> 
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={airportIATACode}
                      getOptionLabel={(option) =>  option.city+" | "+option.IATA}
                      onChange={(e,value)=>{
                        let iataD= value
                        setDeparture(iataD.IATA)
                      }}
                      renderInput={(params) => <TextField {...params} label="Departure City" />}
                    />

                  </Grid>}
                  {airportIATACode&&
                  <Grid item xs={12}  lg={6} md={6}> 
                    <Autocomplete
                      disabled={Departure?false:true}
                      disablePortal
                      id="combo-box-demo"
                      options={airportIATACode}
                      getOptionLabel={(option) =>option.city+" | "+option.IATA}
                      onChange={(e,value)=>{
                        let iataD= value
                        setArrival(iataD.IATA)
                      }}
                      // sx={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label="Arrival City" />}
                    />
                  </Grid>}
                  <Grid item xs={12} >
                    <MKInput
                      type="email"
                      value={PrimaryContactEmailAddress}
                      label={(passengerContactData.EmailAddress?"Prefered email":"Email *")}
                      placeholder={(passengerContactData.EmailAddress?"Enter Prefered email here":"Enter your contact email *")} 
                      onChange={(e)=>{setPrimaryContactEmailAddress(e.target.value)}}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      type="text"
                      // variant="standard"
                      label="Baggage Reference Number  "
                      onChange={(e)=>{setBaggageReferenceNumber(e.target.value)}}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                  <MKInput
                    // variant="standard"
                    label="Description *"
                    placeholder="Describe your request here"
                    onChange={(e)=>{setProblemDescription(e.target.value)}}
                    InputLabelProps={{ shrink: true }}
                    multiline
                    fullWidth
                    rows={6}
                    />
                  </Grid>
                  <Grid item xs={12}>
                  <DropzoneArea 
                  // {maxHeight: "17px",overflow: "hidden"}}
                      dropzoneClass={"dropzoneClass"}
                      showPreviewsInDropzone={false}
                      showPreviews={true}
                      dropzoneText="Click here to add attachment"
                      
                      useChipsForPreview
                      previewGridProps={{container: { spacing: 1, direction: 'row' }}}
                      previewChipProps={{classes: { root: {   minWidth: 160,   maxWidth: 210}} }}
                      previewText="Selected files"
                      onSave={(e)=>{
                        handleSave(e) 
                      }}
                   onChange={(e)=>{handleChange(e)}}
                        />
                  </Grid>
                </Grid>
                
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2} >
                        {(loading&&(loadingProcessName=="creatingservicerequest"))&& 
                        <CircularProgress /> }
                        
                        {responseMessage&&<MKTypography variant="body2" color="warning">
                        { responseMessage }
                        </MKTypography>}
                 </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton variant="gradient"   color="warning" size='large' 
                      onClick={()=>{SubmitRequest()}}
                      >
                    Send Request
                  </MKButton>
                </Grid>
  </> 
  }
  
  const parseHTML=(htmlData)=>{
    let parsedData="";
    try{
      parsedData=parse(htmlData)
    }
    catch(err){

    }
    return parsedData;
  }


  return (
    <> 

        <MetaTags>
           <title>Service Request</title>
      </MetaTags>

    {forceRerender}
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}  
           xl={1}
          >
          <MKBox
            display={{ xs: "none", md: "none" , lg: "none" ,xl: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${bgImage})` }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={10}
          lg={7}
          xl={5}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={1}
          >
            <MKBox 
              variant="gradient"
              // bgColor="info"
              bgColor="success" 
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              
              <Grid container spacing={3}> 
                 <Grid item xs={12} lg={8} md={8}>
                  <MKTypography variant="h3" color="white">
                  Service Request
                  </MKTypography> 
                  </Grid>
                  {(pageMode!=0)&&
                  <Grid item xs={3}  lg={2} md={2}> 
                  <MKButton    size={"small"}  variant="outlined"
                  
                      borderRadius="lg"
                      ml={2}
                      mt={2}
                      // sx={{ backgroundImage: `url(${bgImage})` }}
                      onClick={()=>{setPageMode(0)}}
                      >
                    Back Home
                  </MKButton>  
                  </Grid>}
                  </Grid>
            </MKBox>
            <MKBox py={1}>
              {/* <MKTypography variant="body2" color="text" mb={3}>
                For further questions, including partnership opportunities, please email
                hello@creative-tim.com or contact using our contact form.
              </MKTypography> */}
             { (pageMode==0)&&<>
             <MKBox width="100%" p={0}
                   component="form" method="post" autocomplete="off">
              
             {/* <MKBox width="100%" p={3}
                   > 
              <MKTypography variant="h5" color="text" mb={3}>
                Attention, Valued Visitors!
                
                </MKTypography> 
              <MKTypography variant="body2" color="text" mb={3}>
            


We're currently sprucing up our website to bring you an even better experience! Please bear with us as we work behind the scenes to enhance your browsing adventure.
         </MKTypography> 
              
              <MKTypography variant="body2" color="text" mb={3}>
   

 {" It may take us few days to get back to you. In the mean time we advise our passengers to contact our Global Contact Interaction Center at "}
   <a href = "mailto:Reservation@ethiopianairlines.com">Reservation@ethiopianairlines.com</a>
   
    {" for urgent matters, we appreciate your patience with any delay in reply. "}
              </MKTypography> 
              
               </MKBox> */}
             {/* <>{searchPassengerContactForm()}</> */}
             <iframe
                    ref={ref}
                    // onLoad={onLoad}
                    id="myFrame" 
                    width="100%"
                    // height={height}
                    height={"1000vh"}
                    
                    // height="calc(500vh)"
                    // scrolling="no"
                    frameBorder="0"
                    style={{
                      // maxWidth: 640,
                      width: "100%",
                      overflow: "auto",
                    }}
                    src={"https://ethiopianairlinesgroup2.my.site.com/support/s/?language=en_US"}

                    // src="https://ethiopianairlinesgroup2--training.sandbox.my.site.com/support/s/?language=en_US"
            //  onload="this.height=this.contentWindow.document.body.scrollHeight;"
            //  src="https://ethiopianairlinesgroup2.my.site.com/support/s/?language=en_US"
            //  width= "100%" 
              // style={{overflowY:"auto"}} 
              //  name="iframe"
                title="Create a Case"   />
             
      {/* <IframeResizer
        // forwardRef={iframeRef}
        className={"iframesalesforce"} 
        heightCalculationMethod="lowestElement"
        inPageLinks
        log

        // onMessage={onMessage}
        // onResized={onResized}
        src="https://workspace.google.com/products/meet/"
        style={{border:"0px",  width: '1px', minWidth: '100%', height: '100%'}}
      /> */}
              </MKBox></>
             }
             { (pageMode!=0)&&<>
             <MKBox width="100%" component="form" method="post" autocomplete="off" 
                  mt={{ xs: 2, sm: 1, md: 2 }}
                  mb={{ xs: 2, sm: 1, md: 2 }}
                  >

              { passengerContactDetails()}
               </MKBox>
             <MKBox width="100%" component="form" method="post" autocomplete="off" 
                  mt={{ xs: 2, sm: 1, md: 2 }}
                  mb={{ xs: 1, sm:1, md: 1 }}
                  >
                <AppBar position="static">
                  <Tabs value={pageMode} indicatorColor='primary' onChange={(event, newValue) => {setPageMode(newValue); setViewSearchRequestDetail(false)}}>
                    <Tab label="Create request" color= "success"  value={1} />
                    <Tab label="My requests" color= "success"   value={2} />
                  </Tabs>
                </AppBar>


              </MKBox>
              
              </>
             }
             { (pageMode==1)&&<>{createRequestForm()}</>
             }

             { (pageMode==2)&&<>
             {(!viewSearchRequestDetail)&&
             <MKBox width="100%" component="form" method="post" autocomplete="off">  
                {(serviceRequests.length>0)&&<> 
                <Grid container item xs={12} mt={5} mb={2} > 

                  <List 
                  
                  sx={{ width: '100%',   bgcolor: 'background.paper' }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      My Request Lists
                    </ListSubheader>
                  }>
                            {serviceRequests.map((requestt)=>
                           { 
                            return<><ListItemButton  onClick={()=>{
                              setViewSearchRequestDetail(true);
                              setCurrentServiceRequest(requestt)
                            }}
                                secondaryAction={
                                  <IconButton edge="end" aria-label="delete">
                                    <ViewAgenda />
                                  </IconButton>
                                }
                              >
                                <ListItemAvatar>
                                  <Avatar>
                                    <Folder/>
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={requestt.Title}
                                  secondary= {<span><span style={{color:"green"}}>{requestt.StatusCdMeaning}</span>{" , "+parseDate(requestt.LastUpdateDate)} </span> }
                                />
                              </ListItemButton>
                              <Divider />
                           </>  })}
                 </List> 
                 </Grid>
                 </>}
                 {((serviceRequests.length<=0)&&(loading==false))&&
                 <>
                 <Grid container item justifyContent="center" xs={12} mt={5} mb={2} > 
                          <MKTypography variant="h6" >
                         No Request Found!
                         </MKTypography> 
                  </Grid>
                 </>
                 }
              </MKBox>}

             {(viewSearchRequestDetail)&&
             <MKBox width="100%" component="form" method="post" autocomplete="off">
               
               <Grid container item justifyContent="left" xs={12} mt={5} mb={2}>
                  <MKButton variant="outlined"  color="success" 
                      onClick={()=>{setViewSearchRequestDetail(false)}}
                      >
                    Back to requests
                  </MKButton>
                </Grid> 
               <Grid container item justifyContent="center" xs={12} mt={5} mb={2} > 
               <SingleRequestDetail currentServiceRequest={currentServiceRequest}  passengerTicketData={passengerTicketData} passengerContactData={passengerContactData} hidePartialCharacters={hidePartialCharacters} parseHTML={parseHTML}/>
               </Grid>
            
                 
              </MKBox>}
              </>}
            </MKBox>


          </MKBox>
 
        </Grid>
         
        <Grid item xs={12} lg={3}  xl={5}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${bgImage})` }}
          />
        </Grid>
      </Grid> 




      
    </>
  );
}

export default ServiceRequest;
