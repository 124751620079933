import { useEffect, useRef } from "react";

// typed-js
import * as Typed from "typed.js";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

// CRM app  React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/ethiopian-collection/serviceHostess.jpg";
import { useNavigate } from "react-router-dom";
// import bgImage from "assets/images/bg-about-us.jpg";

function ContactUsWidget() {
  const typedJSRef = useRef(null); 
  const navigate=useNavigate()
  // Setting up typedJS
  useEffect(() => {
    const typedJS = new Typed(typedJSRef.current, {
      strings: ["service request?", "complains?", "doubt?"],
      typeSpeed: 70,
      backSpeed: 70,
      backDelay: 200,
      startDelay: 500,
      loop: true,
    });

    return () => typedJS.destroy();
  }, []);

  return (
    <MKBox component="header" position="relative"> 
      <MKBox
        display="flex"
        alignItems="center"
        minHeight="70vh"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) => `${linearGradient(rgba(gradients.info.main, 0.5), rgba(gradients.info.state, 0.5))}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            mx="auto"
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Do you have any <span ref={typedJSRef} />
            </MKTypography>
            <MKTypography variant="body1" color="white" mt={1} mb={6} px={{ xs: 3, lg: 6 }}>
              {/* The time is now for it to be okay to be great. People in this world shun people for
              being great. For being a bright color. */}
            </MKTypography>
            <MKButton color="success" onClick={()=>{navigate("/pages/support/service-request")}} h >contact us</MKButton>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default ContactUsWidget;