/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
var convert = require('xml-js');
import parse from 'html-react-parser';
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// CRM app  React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// HelpCenter page components
import FaqCollapse from "pages/Support/HelpCenter/components/FaqCollapse";
import { HttpService } from "Api-Services/httpService";
import axios from "axios";
import { mostPopularAnswer } from "Api-master-datas/masterDatas";
import { mostPopularAnswer6 } from "Api-master-datas/masterDatas";

function ContentCatagories(props) {
  const [collapse, setCollapse] = useState(false);

  useEffect(() => {
    (async () => { 
     await getStarted();  
    })() 
  }, []);

  

  const getStarted=async()=>{    

    // await getDocumentsAll("content","/content?limit=20&mode=EXTENDED",{})//init user offices and data   
    // await getDocumentsAll("getCatagories","/categories?limit=1000",{})//init user offices and data   
    // await getDocumentsAll("getContentTypes","/contentTypes",{})//init user offices and data   
    // await getDocumentsAll("getContentType","/contentTypes/3DC39C3342C84A479A1BF2B25B051947/contentSchema",{})//init user offices and data   
  }

  const getDocumentsAll=async( documentName,relativePath ,additionalData )=>{ 
    let responseD={success:false,message:"",record: null};
    let documentsResponse={success:false}  
    ////////// UPDATED/////////////
    documentsResponse=  await HttpService.getService(relativePath,additionalData?additionalData:{},""); 
    // console.log(documentName, " data: ", documentsResponse   );     
    
    if(documentsResponse.success){  
      responseD.success=true;
      responseD.record=documentsResponse.data; 
      responseD.message=documentsResponse.message;  
      let record=responseD.record;
  
      //responseD.record //write conditions down here
  
      if((documentName=="getCatagories")){     
        if(responseD.record){
          // setCatagories(responseD.record.items)  
        }
         }
      if((documentName=="getContentTypes")){    
        if(responseD.record){
        // setContentTypes(responseD.record.items) 
        // getContentTypesSchemas(responseD.record.items) 
        }
         }
      
}
      //  console.log(documentName, " responseD: ", responseD   );   
      //  setForceRerender(!forceRerender);
       return responseD;
    ////////// UPDATED///////////// 
 
}

 
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6} my={6}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
            FAQ categories
            </MKTypography>
            {/* <MKBox mb={2}>
              <MKTypography variant="body2" align="center" color="text">
                A lot of people don&apos;t appreciate the moment until it&apos;s passed. I&apos;m
                not trying my hardest, and I&apos;m not trying to do
              </MKTypography>
            </MKBox> */}
          </Grid>
         { props.catagories&&
          <Grid item xs={12} md={10}>
           {(props.catagories.map((articleD,index)=>{
// return <></>
            // console.log("artt",articleD,)
            // var article = convert.xml2json(articleD.xml, {compact: true, spaces: 4});
            // article = JSON.parse(article)
            // console.log("artt", article) 
            if(articleD.name){
           return (<FaqCollapse
              title={articleD.name}
              open={collapse === index}
              onClick={() => (collapse === index ? setCollapse(false) : setCollapse(index))}
            > 
              {/* {parse(article[articleD.contentType.referenceKey]["ANSWER"]._cdata)} */}
            </FaqCollapse>)
          }
          } )) }
          </Grid>}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ContentCatagories;
