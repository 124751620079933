/* 
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// CRM app  React components
import MKBox from "components/MKBox"; 
import MKTypography from "components/MKTypography"; 
 
// HelpCenter page sections
import SocialAnalytics from "pages/Support/HelpCenter/sections/SocialAnalytics";
import Faq from "pages/Support/HelpCenter/sections/Faq"; 
import Contact from "pages/Support/HelpCenter/sections/Contact";
 

// Images
import bgImage from "assets/images/ethiopian-collection/aircraft1.jpg";
import ContentCatagories from "./sections/ContentCatagories";
import { useEffect, useState } from "react";
import { HttpService } from "Api-Services/httpService";
import AskQuestion from "./sections/AskQuestion";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import { Divider, IconButton, InputBase, Menu, Paper } from "@mui/material";
import { Search } from "@mui/icons-material";
import ContactUsWidget from "./sections/ContactUsWidget";
import { useNavigate } from "react-router-dom";
import { MetaTags } from "react-meta-tags";

function HelpCenter() {
  const [catagories, setCatagories] = useState(null);
  const [searchQuestionText, setSearchQuestionText] = useState("");

  const navigate=useNavigate() 
  useEffect(() => {
    (async () => { 
     await getStarted();  
    })() 
  }, []);

  

  const getStarted=async()=>{    

    // await getDocumentsAll("content","/content?limit=20&mode=EXTENDED",{})//init user offices and data   
    await getDocumentsAll("getCatagories","/categories?limit=1000",{},"api1")//init user offices and data   
    await postDocuments("search","/search/question?question=how+do+i+upgrade+my+device", {"transactionId":0},"api4")//init user offices and data   
    // await getDocumentsAll("getContentTypes","/contentTypes",{})//init user offices and data   
    // await getDocumentsAll("getContentType","/contentTypes/3DC39C3342C84A479A1BF2B25B051947/contentSchema",{})//init user offices and data   
  }

  const getDocumentsAll=async( documentName,relativePath ,additionalData ,apiType)=>{ 
    let responseD={success:false,message:"",record: null};
    let documentsResponse={success:false}  
    ////////// UPDATED/////////////
    documentsResponse=  await HttpService.getService(relativePath,additionalData?additionalData:{},"" ,apiType); 
    // console.log(documentName, " data: ", documentsResponse   );     
    
    if(documentsResponse.success){  
      responseD.success=true;
      responseD.record=documentsResponse.data; 
      responseD.message=documentsResponse.message;  
      let record=responseD.record;
  
      //responseD.record //write conditions down here
  
      if((documentName=="getCatagories")){     
        if(responseD.record){
          setCatagories(responseD.record.items)  
        }
         }
      if((documentName=="getContentTypes")){    
        if(responseD.record){
        // setContentTypes(responseD.record.items) 
        // getContentTypesSchemas(responseD.record.items) 
        }
         }
      
}
      //  console.log(documentName, " responseD: ", responseD   );   
      //  setForceRerender(!forceRerender);
       return responseD;
    ////////// UPDATED///////////// 
 
}


const postDocuments=async(documentName,relativePath,data  ,apiType  )=>{   
  let responseD={success:false,message:"",record: null};
  let documentsResponse={success:false}  
  // console.log("getcommonContent,documentsRequest commonContentId",data,relativePath)

  documentsResponse= await HttpService.postService(relativePath,data,{},"" ,apiType); 
  // console.log("getcommonContent,documentsResponse",documentsResponse)
  
  if(documentsResponse.success){  
    responseD.success=true;
    responseD.record=documentsResponse.data.record; 
    responseD.message=documentsResponse.data.message; 
    let record = responseD.record
         
  if((documentName=="getCommonContent")){
    // console.log("get commonContent", record)

    if(record.length>0){
      // setCommonContent(record[0])  
      }  
    } 
    } 
    
      return responseD;
}


 
  return (
    <> 
    
  <MetaTags>
       <title>Customer Portal</title>
  </MetaTags>
      <MKBox
        minHeight="50vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      > 
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
        }}
      >



<Container> 
      <Grid
          container
          item
          justifyContent="center"
          xs={12}
          lg={6}
          sx={{ mx: "auto", pb: 3, textAlign: "center" }}
        > 
          <MKTypography variant="h2" my={1}>
            Find the answer to your question
          </MKTypography> 
        </Grid> 
        <Grid container spacing={3}  
        
            flexDirection="column"
            justifyContent="center"
            alignItems="center">
          <Grid item xs={11} md={8} lg={8}>
            {/* <MKInput label="Type your question here..." 
              
            onChange={(e)=>{
              // console.log("m",e.target.value);  
            setSearchQuestionText(e.target.value)}}
            /> */}
            <Paper
              // component="form"
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', maxWidth: 500 }}
            >
              <IconButton sx={{ p: '10px' }} aria-label="menu">
                <Menu />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1,width: 700  }}
                placeholder="Type your question here..."
                inputProps={{ 'aria-label': 'Type your question here...' }}
                onChange={(e)=>{
                  // console.log("m",e.target.value);  
                setSearchQuestionText(e.target.value)}}
              />
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search" 
                onClick={()=>{
                  let question=searchQuestionText
                  // searchQuestion()    pagePath+="?pageId="+pageId+"&pageName="+pageName
                
                  navigate("/pages/AskQuestion?question="+question);
                }}>
                <Search/>
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              {/* <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
                <DirectionsIcon />
              </IconButton> */}
            </Paper>
            </Grid>
            
          <Grid item xs={12} md={6} lg={4}> 
            <MKButton variant="gradient" color="success" 
            disable={(searchQuestionText=="")?true:false}
            onClick={()=>{
              let question=searchQuestionText
              // searchQuestion()    pagePath+="?pageId="+pageId+"&pageName="+pageName
              navigate("/pages/AskQuestion?question="+question);
            }}
            >
              search  
            </MKButton>
            </Grid> 
            </Grid>  
      </Container>



        {/* <AskQuestion/> */}
        {/* <SocialAnalytics /> */}
        <Faq />
        <ContactUsWidget/>
       {/* {catagories&& <ContentCatagories catagories={catagories}/>} */}
        {/* <Features /> */}
        {/* <Contact /> */}
      </Card> 
    </>
  );
}

export default HelpCenter;
